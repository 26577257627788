import {
  getShopsListQueryKey,
  shopsList,
  useCartsCountRetrieve,
  useSitesDispatchStatusRetrieve
} from '@/api/generated/hooks'
import { DeliveryTypesEnum, ShopRes, ShopsListOrderByItem, ShopsListParams } from '@/api/generated/types'
import { RobotStatusBottomSheetDataType } from '@/components/home/RobotStatusBottomSheet'
import { ServiceFinishHideOnlyTodayInfoType } from '@/components/home/ServiceFinishBottomSheet'
import { PolicyBottomSheetDataType, PolicyTypeEnum } from '@/components/policy/PolicyBottomSheet'
import { Routes } from '@/constants/routes'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import AuthContainer from '@/containers/app/AuthContainer'
import useBottomSheetLegacy, { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import useInfinityScroll from '@/hooks/common/useInfinityScroll'
import useToast from '@/hooks/common/useToast'
import { DateUtils } from '@/utils/date'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { createContainer } from 'unstated-next'
import PromiseNodeNumberContainer from '@/containers/app/PromiseNodeNumberContainer'
import SiteSlugContainer from '@/containers/common/SiteSlugContainer'

dayjs.extend(isBetween)

const useHome = () => {
  const { push, replace } = useRouter()
  const {
    isReady: isPromiseNodeNumberContainerReady,
    getPromiseNodeNumber,
    updatePromiseNodeNumber
  } = PromiseNodeNumberContainer.useContainer()

  const { site, siteSlug, sites: siteList, updateSiteSlug } = SiteSlugContainer.useContainer()
  const policyBottomSheetControls = useBottomSheetLegacy<PolicyBottomSheetDataType>()
  const loadInfoBottomSheetControls = useBottomSheetLegacy()
  const badWeatherBottomSheetControls = useBottomSheetLegacy(true)
  const robotStatusBottomSheetControls = useBottomSheetLegacy<RobotStatusBottomSheetDataType>()
  const secSuwonServiceFinishBottomSheetControls = useBottomSheetLegacy()
  const teheranServiceFinishBottomSheetControls = useBottomSheetLegacy()
  const toastControls = useToast()
  const { isLogin } = AuthContainer.useContainer()

  useEffect(() => {
    if (site && isPromiseNodeNumberContainerReady) {
      const decideServiceFinishBottomSheetOpen = (
        targetSiteSlug: string,
        startDate: string,
        endDate: string,
        bottomSheetControls: BottomSheetLegacyControlsType
      ) => {
        const isTodayInTerms = dayjs().isBetween(dayjs(startDate), dayjs(endDate), null, '[]')
        if (siteSlug === targetSiteSlug && isTodayInTerms) {
          const hideOnlyTodayInfo =
            LocalStorage.getItem<ServiceFinishHideOnlyTodayInfoType>(LocalStorageKeyEnum.ServiceFinishHideOnlyToday) ??
            {}
          const doNotShowClickDay = hideOnlyTodayInfo[siteSlug]
          const toDay = DateUtils.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')

          if (toDay !== doNotShowClickDay) {
            bottomSheetControls.handleOpen()
          }
        }
      }
      /**
       * siteSlug와 nodeNumber를 query parmas로 전달 받아서 설정하는 로직
       */
      const query = Object.fromEntries(new URLSearchParams(window.location.search))
      const { siteSlug: siteSlugNew, nodeNumber: nodeNumberNew, ...queryRemainder } = query
      const isSiteSlugDifferent = siteSlugNew && siteSlugNew !== siteSlug
      if (isSiteSlugDifferent) {
        LocalStorage.setItem(LocalStorageKeyEnum.SiteSlug, siteSlugNew)
      }
      const nodeNumber = getPromiseNodeNumber(site.id)
      const isNodeNumberDifferent = nodeNumberNew && nodeNumberNew !== nodeNumber
      if (isNodeNumberDifferent) {
        updatePromiseNodeNumber(site.id, nodeNumberNew)
      }
      if (isSiteSlugDifferent || isNodeNumberDifferent) {
        replace({ pathname: Routes.Home, query: queryRemainder })
      }

      decideServiceFinishBottomSheetOpen(
        SiteSlugEnum.Secsuwon,
        '2023-11-10 16:30:00',
        '2024-11-10 16:30:00',
        secSuwonServiceFinishBottomSheetControls
      )
      decideServiceFinishBottomSheetOpen(
        SiteSlugEnum.Teheran,
        '2024-01-31 12:00:00',
        '2029-01-31 12:00:00',
        teheranServiceFinishBottomSheetControls
      )
    }
  }, [site, isPromiseNodeNumberContainerReady])

  const { data: shopList, fetchNextPage: fetchNextShopList } = useInfinityScroll<ShopRes, ShopsListParams>(
    {
      queryKey: getShopsListQueryKey({ orderBy: [ShopsListOrderByItem.is_available] }),
      queryFn: shopsList,
      itemPerPage: 5
    },
    {
      query: {
        cacheTime: 60 * 60 * 1000
      }
    }
  )

  const { data: cartCount } = useCartsCountRetrieve({
    query: {
      cacheTime: 0,
      enabled: !!isLogin
    }
  })
  const { data: robotStatus } = useSitesDispatchStatusRetrieve({
    query: {
      cacheTime: 0
    }
  })
  /**
   * 가용 로봇 상태 바텀 시트
   */
  useEffect(() => {
    if (!robotStatus || !site) return

    // 기상악화
    if (!site.isAvailable) {
      badWeatherBottomSheetControls.handleOpen()
      return
    }

    // 로봇 가용 대수가 있다면 뜨지 않습니다.
    // 가용로봇 바텀시트를 첫 한번만 노출 합니다.
    const isFirstRenderRobotStatusSheet = SessionStorage.getItem<boolean>(
      SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet
    )
    // 주문 가능 카운트가 있으면 가용로봇 바텀시트 노출상태를 초기화 합니다.
    if (robotStatus.orderCanDispatchCount) {
      SessionStorage.removeItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet)
      return
    }

    const isSiteBreakTime = DateUtils.getNowIsAble(site?.breakStartAt, site?.breakEndAt)
    const isSiteOpen = DateUtils.getNowIsAbleWithEmpty(site?.openAt, site?.lastOrderAt)
    // 사이트 브레이크 타임이거나, 사이트 오픈 시간이 아니라면 로봇 상태 바텀시트를 띄우지 않습니다.
    if (!isSiteOpen || isSiteBreakTime) return

    // 이미 가용로봇 바텀시트를 띄운적이 있다면 띄우지 않습니다.
    if (isFirstRenderRobotStatusSheet === false) return

    // 가용로봇 상태를 띄우고 띄운적이 있다는 상태로 변경합니다.
    robotStatusBottomSheetControls.setBottomSheetData({ robotStatus })
    robotStatusBottomSheetControls.handleOpen()
    SessionStorage.setItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet, false)
  }, [robotStatus, site])

  const handleOpenPolicy = (type: PolicyTypeEnum) => {
    policyBottomSheetControls.setBottomSheetData({ selectedPolicyType: type })
    policyBottomSheetControls.handleOpen()
  }

  const handleOpenLoadInfo = () => {
    loadInfoBottomSheetControls.handleOpen()
  }

  const handleDeliveryTap = (deliveryType: DeliveryTypesEnum) => {
    if (deliveryType === DeliveryTypesEnum.FOOD) {
      push(Routes.Home)
    }
    if (deliveryType === DeliveryTypesEnum.DOCUMENT)
      if (robotStatus?.orderCanDispatchCount) {
        push(Routes.DeliveryDocument)
      } else {
        if (!robotStatus) return
        robotStatusBottomSheetControls.setBottomSheetData({ robotStatus })
        robotStatusBottomSheetControls.handleOpen()
      }
  }

  return {
    policyBottomSheetControls,
    loadInfoBottomSheetControls,
    robotStatusBottomSheetControls,
    badWeatherBottomSheetControls,
    secSuwonServiceFinishBottomSheetControls,
    teheranServiceFinishBottomSheetControls,
    toastControls,
    handleOpenPolicy,
    handleOpenLoadInfo,
    handleDeliveryTap,
    cartCount: cartCount?.count,
    shopList,
    fetchNextShopList,
    site,
    siteList,
    siteSlug,
    robotStatus,
    updateSiteSlug
  }
}

const HomeContainer = createContainer(useHome)

export default HomeContainer
