import { motion } from 'framer-motion'
import { PropsWithChildren, ReactNode, useEffect } from 'react'
import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import classNames from 'classnames'
import Portal from '@/components/common/Portal'
import { StyleUtils } from '@/utils/style'
import { BottomSheetControlsType } from '@/hooks/common/useBottomSheet'
import { ComponentUtils } from '@/utils/design-system/componentUtils'

export type BottomSheetProps = {
  bottomSheetControls: BottomSheetControlsType
  className?: string
  background?: string
  height?: string
  topSide?: ReactNode
  handleDimClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * 디자인 시스템을 따르는 바텀 시트 레이아웃 입니다.
 *
 * @param bottomSheetControls
 * @param className
 * @param children
 * @param background
 * @constructor
 */
const BottomSheet = ({
  bottomSheetControls,
  className,
  children,
  background = 'bg-white',
  height = 'h-fit',
  topSide,
  handleDimClick
}: PropsWithChildren<BottomSheetProps>) => {
  const { mount, handleClose, controls } = bottomSheetControls

  useEffect(() => {
    if (!mount) {
      StyleUtils.ableMainScroll()
    } else {
      StyleUtils.disabledMainScroll()
    }
    return () => {
      StyleUtils.ableMainScroll()
    }
  }, [mount])

  if (!mount) {
    return null
  }

  return (
    <Portal id="bottom-sheet">
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.3 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 }
        }}
        onClick={(e) => {
          e.preventDefault()
          handleDimClick?.(e)
        }}
        className="fixed bottom-0 h-screen w-screen max-w-body cursor-pointer bg-gray-900/50"
      />
      <motion.div
        animate={controls}
        initial="hidden"
        role="dialog"
        transition={{
          damping: 40,
          stiffness: 400,
          type: 'spring',
          duration: 0.3
        }}
        variants={{
          hidden: { y: 2000 },
          visible: { y: 0 }
        }}
        className={classNames(`fixed bottom-0 flex w-full max-w-body flex-col justify-end`)}>
        {topSide}
        <div className={classNames('flex w-full flex-col rounded-t-md', background, height, className)}>{children}</div>
      </motion.div>
    </Portal>
  )
}

type BottomSheetHeaderProps = {
  bottomSheetControls: BottomSheetControlsType
  isShowCloseButton?: boolean
  className?: string
}
const BottomSheetHeader = ({
  children,
  bottomSheetControls,
  isShowCloseButton = false,
  className
}: PropsWithChildren<BottomSheetHeaderProps>) => {
  return (
    <header className="relative mt-32 flex h-40 w-full items-start justify-center bg-white px-24 pb-16">
      <div className={ComponentUtils.cn('body1 font-bold', className)}>{children}</div>
      {isShowCloseButton && (
        <Icon
          name={IconNamesEnum.Close}
          className="absolute right-20 h-24 w-24 cursor-pointer"
          onClick={() => bottomSheetControls.handleClose()}
        />
      )}
    </header>
  )
}

export default BottomSheet

BottomSheet.Header = BottomSheetHeader
